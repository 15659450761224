import { getDomainFromEmail } from './getDomainFromEmail';

export function checkFeature(feature) {
    if (typeof window === 'undefined') return false;
    if (feature === 'salaryMandatory' && window.salaryMandatoryWhitelisting) {
        const domain = getDomainFromEmail(window.profile.email);
        return (
            Boolean(+window.features[feature]) &&
            !window.salaryMandatoryWhitelisting.split(',').includes(domain)
        );
    }
    return window.features && Boolean(+window.features[feature]);
}
